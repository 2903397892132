
export enum AlertType {
  Info = 'info',
  Good = 'good',
  Warn = 'warn',
  Error = 'error'
}

export interface Alert {
  title: string,
  message: string,
  properties?: object[]
  type?: AlertType | undefined,
}

export const defaultAlerts = {
  failedToReachPayer: {
    title: 'Unable to complete step',
    message: 'We were unable to communicate with the payer.',
    type: AlertType.Error,
  },
  uploadStarted: {
    title: 'Uploading attachment',
    message: 'We are working on uploading the attachment.',
    type: AlertType.Info,
  },
  uploadSuccessful: {
    title: 'Upload successful',
    message: 'We have successfully uploaded and processed the attachment.',
    type: AlertType.Good,
  },
  uploadError: {
    title: 'Upload error',
    message: 'There was an error uploading the attachment.',
    type: AlertType.Error,
  },
  workflowLoadUnauthorized: {
    title: 'Unable to load authorization workflow',
    message: 'The user is not authorized.',
    type: AlertType.Error,
  },
  workflowLoadNotFound: {
    title: 'Unable to load authorization workflow',
    message: 'The requested authorization workflow could not be found.',
    type: AlertType.Error,
  },
  workflowLoadError: {
    title: 'Unable to load authorization workflow',
    message: 'There was an error loading the authorization workflow.',
    type: AlertType.Error,
  },
} as Record<string, Alert>