import type { BlockItemConfig } from "./_Types";

export default [
  {
    type: 'InputSearch',
    label: 'Service Type',
    prop: 'serviceTypeCode'
  },
  {
    type: 'InputSearch',
    label: 'Additional Service Type',
    prop: 'additionalServiceTypes'
  },
  {
    type: 'InputSearch',
    label: 'Place of Service',
    prop: 'placeOfServiceCode'
  },
  {
    type: 'InputText',
    label: 'Member Group Number',
    prop: 'subscriber.groupNumber',
  },
  {
    type: 'InputDate',
    label: 'From Date',
    prop: 'fromDate'
  },
] as BlockItemConfig[]