import { nextTick } from 'vue'
import { defineStore } from 'pinia'
import propRetrievalService from "@/services/propRetrievalService"
import { WorkflowService, type WorkflowOverviewViewModel, type AttestationProgressViewModel, type LightweightWorkflowStepViewModel, type WorkflowBlockViewModel, type ServiceReview, type LightweightWorkflowStepViewModelWorkflowViewModel, type OtterServiceReviewViewModel, type ServiceReviewAttachment, RequestTypeEnum, type AttachmentConfigurationViewModel, type WorkflowPropertyViewModel, type PatientAndSubscriberViewModel, PatientsService, ProvidersService, type ServiceReviewRequestingProvider, type ServiceReviewRenderingProvider, DiagnosesService, type ServiceReviewDiagnosis, ProceduresService, type ServiceReviewProcedure, type NextWorkflowStepViewModel, BlockType, type TrackingWorkflowStepViewModel, type WorkflowStepViewModel, BlobDocumentsService, TransportLocationsService, type ServiceReviewTransportLocation, AuthAiService } from "@/api"
import debugLoggingService from '@/services/debugLoggingService'
import { type AttestationResult } from '@/types/attestation'
import { useUploadStore } from './uploadStore'
import { isApiError, isStatusCode } from '@/shared/apiUtils'
import { defaultAlerts, type Alert, AlertType } from '@/shared/alerts'
import { CompleteStepOverrideOption } from '@/types/completeStepOverrides'
import type { ProblemDetails } from '@/types/api'
import globalEventService, { type EpaRedirectEventPayload, GlobalEvents } from '@/services/globalEventService'
import type { BlockConfig, BlockItemConfig } from '@/components/blockConfigs/_Types'
import { BlockMap } from '@/components/blockConfigs/_BlockMap'
import type { BlockWithProps, PropertyList, Step } from '@/types/step'
import { dateService } from '@availity/element-vue'
import { useAlertStore } from './alertStore'
import { OpenAPI } from "@/api"
import logger from '@/shared/logger'

type Headers = Record<string, string>;

export interface State {
  testHandlerId: string | undefined,
  customerId: string | undefined,
  organizationId: string | undefined,
  payerId: string | undefined,
  requestType: RequestTypeEnum | undefined,
  isOriginalEssentialsWorkflow: Boolean,
  otterServiceReview: OtterServiceReviewViewModel | null | undefined
  workflow: LightweightWorkflowStepViewModelWorkflowViewModel | null | undefined,
  steps: Array<LightweightWorkflowStepViewModel> | null | undefined,
  workflowStepId: number | undefined,
  workflowProperties: WorkflowPropertyViewModel[] | undefined,
  workflowCurrentStep: WorkflowStepViewModel | null | undefined,
  locationRoleCodes: String[] | undefined,
  blocks: Array<WorkflowBlockViewModel> | null | undefined,
  validationErrors: object,
  completingStep: boolean,
  workflowLoadStatusCode: number | undefined,
  stepAlert: Alert | undefined,
  completeStepOverrides: CompleteStepOverrideOption[],
  draftDashboardId: string | undefined,
  eligibilitySearchResponse: EligibilitySearchResponse | undefined,
  searchingEligibility: boolean,
  analyzedDocumentsResult: AttestationProgressViewModel | undefined,
  hasAllAttestationEntryLinesComplete: Boolean,
  attestationMFEToken: string | undefined, 
  attestationResults: AttestationResult[] | undefined
}

export interface EligibilitySearchResponse {
  notFound: boolean,
  alert?: Alert,
}

export interface ExtendedNextWorkflowStepViewModel extends NextWorkflowStepViewModel {
  errors?: object | undefined | null
}

type BlockOrStep = WorkflowBlockViewModel | WorkflowStepViewModel
const orderSort = (a: BlockOrStep, b: BlockOrStep) => a.order! - b.order!

export const useAuthorizationStore = defineStore('authorization', {
  state: (): State => ({
      testHandlerId: undefined,
      customerId: undefined,
      organizationId: undefined,
      payerId: undefined,
      requestType: undefined,
      isOriginalEssentialsWorkflow: false,
      otterServiceReview: undefined,
      workflow: undefined,
      workflowProperties: undefined,
      workflowCurrentStep: undefined,
      locationRoleCodes: undefined, // TODO: Get this from the backend
      steps: undefined,
      workflowStepId: undefined,
      blocks: undefined,
      validationErrors: {},
      completingStep: false,
      workflowLoadStatusCode: undefined,
      stepAlert: undefined,
      completeStepOverrides: [CompleteStepOverrideOption.ValidateInputs, CompleteStepOverrideOption.ValidateWithPayer],
      draftDashboardId: undefined,
      eligibilitySearchResponse: undefined,
      searchingEligibility: false,
      hasAllAttestationEntryLinesComplete: false,
      attestationMFEToken: undefined,
      analyzedDocumentsResult: undefined,
      attestationResults: undefined,
  }),
  getters: {
    APIheaders(): Headers | undefined {
      if(!this.customerId && !this.testHandlerId){
        return undefined
      }
      let headers = {}
      if(this.customerId){
        headers['X-AVAILITY-CUSTOMER-ID'] = this.customerId
      }

      if(this.testHandlerId){
        headers['X-Test-Handler-Suite-ID'] = this.testHandlerId
      }
      return headers
    },
    isAuthAIWorkflow(): boolean {
      return this.otterServiceReview?.isAuthAIWorkflow ?? false
    },
    serviceReview(): ServiceReview | null | undefined {
      return this.otterServiceReview?.serviceReview
    },
    stepBlocksWithProps(): Step[] {
      try {
        let visitedSteps = Object.values(this.otterServiceReview?.visitedSteps ?? {}) as (TrackingWorkflowStepViewModel | WorkflowStepViewModel)[]
        visitedSteps = visitedSteps.sort(orderSort)

        if (this.workflowCurrentStep) {
          const index = visitedSteps.findIndex(s => {
            return s.workflowStepId === this.workflowCurrentStep?.workflowStepId
          })
          const filtered = (index > -1) ? visitedSteps.slice(0, index) : visitedSteps
          visitedSteps = [...filtered, this.workflowCurrentStep]
        }

        const mapBlock = (b: WorkflowBlockViewModel): BlockConfig => {
          let block = BlockMap?.find(bm => {
            return b.blockType === bm.blockType
          })
          if (block) {
            block = {...block}
          } else {
            block = {
              title: b.blockName ?? '',
              model: null,
              component: '',
              blockType: b.blockType ?? BlockType.ImportTemp
            } as BlockConfig
          }
          return block
        }
        
        return visitedSteps.reduce((acc, step) => {
          const active = step.workflowStepId === this.workflowStepId
          const initialPropertyList = {
            triggerReload: [],
            allProps: [],
            isUIProperty: [],
            isUIEditableProperty: [],
            isUIDisabledProperty: [],
            required: [],
            validationErrors: {}
          } as PropertyList
          const defaultStepProperties = { properties: initialPropertyList as PropertyList,  propsByBlockType: {} as Record<BlockType, WorkflowPropertyViewModel[]> }
          const propsToReduce = active ? this.workflowProperties : step.workflowProperties // use potentially mutated workflow prop list if active step
          const stepProperties = propsToReduce?.reduce((result, prop) => {
            if(!result.propsByBlockType[prop.blockType!]) {
              result.propsByBlockType[prop.blockType!] = []
            }
            result.propsByBlockType[prop.blockType!].push(prop)

            result.properties.allProps.push(prop.propertyName!)
            if(prop.isRequired && prop.isServiceReviewProperty) {
              result.properties.required.push(prop.propertyName!)
            }
            if(prop.isUIProperty) {
              result.properties.isUIProperty.push(`${prop.propertyName}-${prop.blockType}`)
            }
            if (prop.isUIEditableProperty) {
              result.properties.isUIEditableProperty.push(`${prop.propertyName}-${prop.blockType}`)
            }
            if(prop.isUIDisabledProperty) {
              result.properties.isUIDisabledProperty.push(`${prop.propertyName}`)
            }
            if(prop.doesPropertyChangeTriggerReload) {
              result.properties.triggerReload.push(prop.propertyName!)
            }
            if(prop.errorMessage) {
              result.properties.validationErrors[prop.propertyName!]= prop.errorMessage
            }
            return result
          }, defaultStepProperties) ?? defaultStepProperties

          const blocksToReduce = ((active ? this.blocks : step.blocks) ?? []).sort(orderSort)
          const blocksWithProps = blocksToReduce?.reduce((blocks: BlockWithProps[], block: WorkflowBlockViewModel) => {
            const blockWithProps = {
              config: mapBlock(block),
              properties: stepProperties.propsByBlockType[block.blockType!]
            } as BlockWithProps          
            blockWithProps.blockId = block.workflowBlockId
            blockWithProps.stepId = step.workflowStepId
            blockWithProps.disabledByProperties = block.disabledByProperties ?? undefined
            blockWithProps.enabledByProperties = block.enabledByProperties ?? undefined

            return [...blocks, blockWithProps]
          }, [] as BlockWithProps[]) ?? []

          const fullStep = {
            stepId: step.workflowStepId ?? 0,
            active,
            properties: stepProperties.properties,
            blocks: blocksWithProps,
          } as Step

          acc = [...acc, fullStep]
          return acc
        }, [] as Step[])
      }
      catch(err) { console.log(err) }
      return []
    },
    currentStep(): Step | undefined {
      return this.stepBlocksWithProps.find(s => s.active)
    },
    nextButtonMessage(): string {
      return this.workflowCurrentStep?.nextButtonMessage ? this.workflowCurrentStep?.nextButtonMessage : 'Next'
    },
    isStepLoading(): boolean {
      return this.completingStep || this.searchingEligibility || !this.isWorkflowLoaded
    },
    nextStepLoadingMessage(): string {
      if (!this.isWorkflowLoaded) {
        return 'One Moment'
      }
      if (this.searchingEligibility) {
        return 'Searching eligibility...'
      }
      return this.workflowCurrentStep?.nextStepLoadingMessage ? this.workflowCurrentStep?.nextStepLoadingMessage : 'Completing step...'
    },
    previousWorkflowStep(state): number | false {
      if (!state.steps) {
        return false
      }
      let currentStepIndex = state.steps.findIndex(s => {
        //console.log(s.workflowStepId, state.workflowStepId)
        return s.workflowStepId == state.workflowStepId
      })
      if(currentStepIndex > 0) {
        return state.steps[currentStepIndex - 1].workflowStepId ?? false
      }
      return false
    },
    hasValidationErrors(): boolean {
      return !!(this.validationErrors && Object.keys(this.validationErrors).length)
    },
    isAuthAiAttachments(): boolean | undefined {
      const property = this.workflowProperties?.find(p => p.propertyName === 'attachments.isAuthAI')
      return property?.isAllowed ?? undefined
    },
    attachmentValidationConfig(): AttachmentConfigurationViewModel | null | undefined {
      const property = this.workflowProperties?.find(p => p.propertyName === 'attachments.validation')
      if (!property && this.isAuthAiAttachments) {
        return {
          max: {
            size: 20971520,
            errorMessage: 'Larger than 20MB',
          },
        }
      }
      return property?.allowedValues?.attachmentValidation
    },
    canLoadWorkflow(): boolean {
      return !!(this.payerId && this.requestType && this.organizationId) && !this.workflowLoadStatusCode
    },
    isWorkflowLoaded(): boolean {
      return !!this.workflow
    },
    workflowLoadAlert(): Alert | undefined {
      if (this.workflowLoadStatusCode) {
        // status code is only set if there is an error
        switch (this.workflowLoadStatusCode) {
          case 401:
            return defaultAlerts.workflowLoadUnauthorized
          case 404:
            return defaultAlerts.workflowLoadNotFound
          default:
            return defaultAlerts.workflowLoadError
        }
      }
      return undefined
    },
    // isLastStepInWorkflow(): boolean {
    //   return this.isWorkflowLoaded &&
    //     this.workflowStepId === this.steps?.slice(-1)[0].workflowStepId
    // },
    isLastStepInWorkflow(): boolean {
      return this.workflowCurrentStep?.isFinalStep ? this.workflowCurrentStep?.isFinalStep : false
    },
    isSubmissionResultView(): boolean {
      return this.currentStep?.blocks?.length === 1 &&
        this.currentStep.blocks[0].config.blockType === BlockType.SubmissionResult
    },
    isFirstStepInWorkflow(): boolean {
      return this.isWorkflowLoaded && !!this.stepBlocksWithProps &&
        this.workflowStepId === this.stepBlocksWithProps[0].stepId
    },
    stepIndexInWorkflow(): number | undefined {
      if (this.isWorkflowLoaded) return this.steps?.map( e => { return e.workflowStepId}).indexOf(this.workflowStepId)
      else return -1
    },
    showLegacy(): boolean {
      return this.completeStepOverrides.includes(CompleteStepOverrideOption.LegacyLookAndFeel)
    },
    stepsInView(): Step[] {
      // TODO: GET THESE VALUES FROM THE BACKEND ON THE STEPS
      const supressedWorkflowStepIds = [276]
      if (this.showLegacy || this.stepBlocksWithProps.length < 1) {
        return this.stepBlocksWithProps
      }

      if (!this.isFirstStepInWorkflow && this.stepBlocksWithProps.length > 1) {
        let filteredSteps =  this.stepBlocksWithProps.filter(s => {
          if(s.stepId === this.currentStep?.stepId) {
            return s
          }
          if(!supressedWorkflowStepIds.includes(s.stepId)){
            return s 
          }
        })
        return filteredSteps.slice(1)       
      }

      if (this.isSubmissionResultView) {
        return []
      }

      return this.stepBlocksWithProps
    },
  },
  actions: {
    resetAuthorization() {
      useUploadStore().$reset()
      OpenAPI.HEADERS = undefined
      this.$reset()
    },
    setOpenAPIHeaders() {
      OpenAPI.HEADERS = this.APIheaders
    },
    selectWorkflow(organizationId: string, payerId: string, requestType: RequestTypeEnum, isOriginalEssentialsWorkflow: Boolean = false) {
      this.resetAuthorization()
      
      this.organizationId = organizationId
      this.payerId = payerId
      this.requestType = requestType      
      this.isOriginalEssentialsWorkflow = isOriginalEssentialsWorkflow
    },
    setStateProp(value: object | undefined | null,) {
      if (!value) {
        return
      }
      
      debugLoggingService.log('Update Service Review Property', value)

      const allowed = this.currentStep?.properties.allProps ?? []

      Object.keys(value).forEach((key) => {
        if (allowed.includes(key)) {
          propRetrievalService.setPropertyValue(this.serviceReview!, key, value[key])
        } else {
          debugLoggingService.error(`${key} NOT ALLOWED`)
        }
      })
    },
    resetBlockItemValues(blockItems: BlockItemConfig[]) {
      const allowed = this.currentStep?.properties.allProps ?? []
      for (const blockItem of blockItems) {
        if (blockItem.prop && allowed.includes(blockItem.prop)) {
          propRetrievalService.setPropertyValue(this.serviceReview!, blockItem.prop, undefined)
          debugLoggingService.log('Resetting Service Review Property', { property: blockItem.prop })
        }
      }
    },
    setWorkflowSteps(steps: Array<LightweightWorkflowStepViewModel> | null | undefined) {
      if(!steps) {
        this.steps = undefined
      } else {
        this.steps = steps.sort(orderSort)
      }
    },
    async getAttestationToken() {
      if(!this.workflow?.workflowId || !this.organizationId) {
        return
      }
      await AuthAiService.getAttestationToken(this.workflow?.workflowId, this.organizationId)
        .then(res => {
          this.attestationMFEToken = res
        })
        .catch(err => {
          this.attestationMFEToken = undefined
        })
    },
    async getAttestationProgress() {
      if(!this.otterServiceReview?.paRequestId) {
        return
      }
      await AuthAiService.getAttestationProgress(this.organizationId!, this.otterServiceReview?.paRequestId, this.workflow?.workflowId)
        .then(res => {
          this.analyzedDocumentsResult = res
        })
        .catch(err => {
          this.analyzedDocumentsResult = undefined
        })
    },
    applyCopyOrDraft(res: WorkflowOverviewViewModel) {
      this.otterServiceReview = res.otterServiceReview
      this.payerId = res.otterServiceReview?.serviceReview?.payer?.id ?? undefined
      this.requestType = RequestTypeEnum[res.otterServiceReview?.serviceReview?.requestTypeCode ?? '']
      this.organizationId = res.otterServiceReview?.serviceReview?.organization?.id ?? undefined
      this.isOriginalEssentialsWorkflow = false

      this.setWorkflowSteps(res.firstStep?.path)
      this.workflowStepId = res.firstStep?.workflowStepId
      this.workflow = res.workflow
      this.locationRoleCodes = res.workflow?.locationRoleCodes ?? []
      this.setDefaultLegacySwitch()
      this.blocks = res.firstStep?.blocks?.sort(orderSort)
      this.workflowProperties = res.firstStep?.workflowProperties ?? []
      this.workflowCurrentStep = res.firstStep
      this.onNewOtterServiceReviewSet()
    },
    async copyServiceReview(dashboardId: string, customerId: string) {
      this.resetAuthorization()
      this.customerId = customerId
      this.setOpenAPIHeaders()
      this.completingStep = true
      debugLoggingService.log('Copying Service Review', { dashboardId })
      this.draftDashboardId = dashboardId

      await WorkflowService.copyServiceReview(dashboardId)
        .then(async res => {
          this.applyCopyOrDraft(res)
          logger.logLoadWorkflow('copyServiceReview')
        })
        .catch(err => {
          const apiError = isApiError(err)
          this.workflowLoadStatusCode = apiError ? apiError.status : err.status ? err.status : 500
          // throw err // bubble up to 401 auth handling
        })
        .finally(() => {
          this.completingStep = false
        })
    },
    async loadDraft(dashboardId: string, customerId: string) {
      this.resetAuthorization()
      this.customerId = customerId
      this.setOpenAPIHeaders()
      this.completingStep = true
      debugLoggingService.log('Loading Draft', { dashboardId })
      this.draftDashboardId = dashboardId

      await WorkflowService.loadServiceReview(dashboardId)
        .then(async res => {
          this.applyCopyOrDraft(res)
          logger.logLoadWorkflow('loadDraft')
        })
        .catch(err => {
          const apiError = isApiError(err)
          this.workflowLoadStatusCode = apiError ? apiError.status : err.status ? err.status : 500
          // throw err // bubble up to 401 auth handling
        })
        .finally(() => {
          this.completingStep = false
        })
    },
    async getWorkflowOverview(customerId: string | undefined = undefined) {
      debugLoggingService.log('Loading Workflow Overview', { 
        payerId: this.payerId,
        requestType: this.requestType,
        organizationId: this.organizationId,
        isOriginalEssentialsWorkflow: this.isOriginalEssentialsWorkflow,
        customerId: customerId,
      })
      if(customerId) {
        this.customerId = customerId
        this.setOpenAPIHeaders()
      }
      await WorkflowService.getWorkflowOverview(this.payerId!, this.requestType!, this.organizationId!, this.isOriginalEssentialsWorkflow as boolean)
        .then(res => {
          this.customerId = res.otterServiceReview?.serviceReview?.customerId || undefined
          debugLoggingService.log('Workflow Overview Loaded', res)
          this.setWorkflowSteps(res.workflow?.steps)
          this.workflowStepId = res.firstStep?.workflowStepId
          this.workflow = res.workflow
          this.locationRoleCodes = res.workflow?.locationRoleCodes ?? []
          this.setDefaultLegacySwitch()
          this.blocks = res.firstStep?.blocks?.sort(orderSort)
          this.workflowProperties = res.firstStep?.workflowProperties ?? []
          this.workflowCurrentStep = res.firstStep

          sessionStorage.setItem('otterWorkflowParams', `{ "payerId": "${this.payerId}", "requestType": "${this.requestType}", "organizationId": ${this.organizationId}, "isOriginalEssentialsWorkflow": ${this.isOriginalEssentialsWorkflow} }`)
          
          if (!this.draftDashboardId) { // otherwise a draft service review was already loaded
            this.otterServiceReview = res.otterServiceReview
          this.onNewOtterServiceReviewSet()
          }
          this.setOpenAPIHeaders()
          logger.logLoadWorkflow('getWorkflowOverview')
        })
        .catch(err => {
          const apiError = isApiError(err)
          this.workflowLoadStatusCode = apiError ? apiError.status : err.status ? err.status : 500
          // throw err // bubble up to 401 auth handling
        })
    },
    async resetServiceReview() {
      const tempMessage = this.workflowCurrentStep?.nextStepLoadingMessage
      if(this.workflowCurrentStep) {
        this.workflowCurrentStep.nextStepLoadingMessage = 'One Moment'
      }

      this.completingStep = true
      await WorkflowService.resetServiceReview(this.otterServiceReview!) 
      .then(res => {
        if (!res.nextStep) {
          throw new Error('Next step not defined in complete step response.')
        }
        debugLoggingService.log('Workflow Step Completed', res)
        this.stepAlert = undefined
        this.workflowStepId = res.nextStep.workflowStepId
        this.validationErrors = {}
        this.otterServiceReview = res.otterServiceReview
        this.blocks = res.nextStep?.blocks?.sort(orderSort)
        this.workflowProperties = res.nextStep.workflowProperties ?? []
        this.workflowCurrentStep = res.nextStep
        this.setWorkflowSteps(res.nextStep.path)
        sessionStorage.setItem('otterDraftDashboardId', this.otterServiceReview?.serviceReview?.dashboardId ?? 'null')
        this.resetEligibilitySearchResponse()
        useUploadStore().$reset()
      })
      .catch(err => {
        const apiError = isApiError(err)
        if (apiError) {
          if (isStatusCode(apiError, 424)) {
            this.stepAlert = defaultAlerts.failedToReachPayer
          } else if (apiError.body?.detail) {
            this.stepAlert = {
              title: 'Unable to complete step',
              message: apiError.body.detail,
              type: AlertType.Error,
            }
          }
          this.validationErrors = apiError.body?.errors || apiError.body
        }
        throw err // bubble up to 401 auth handling
      })
      .finally(() => {
        this.completingStep = false
        if(this.workflowCurrentStep) {
          this.workflowCurrentStep.nextStepLoadingMessage = tempMessage
        }
      })
    },
    setDefaultLegacySwitch() {
      if (this.workflow?.isOriginalEssentialsWorkflow && !this.showLegacy) {
        this.completeStepOverrides.push(CompleteStepOverrideOption.LegacyLookAndFeel)
      } else if (!this.workflow?.isOriginalEssentialsWorkflow && this.showLegacy) {
        this.completeStepOverrides = this.completeStepOverrides.filter(o => o !== CompleteStepOverrideOption.LegacyLookAndFeel)
      }
    },
    async completeWorkflowStep() {
      this.completingStep = true

      await WorkflowService.completeStep(
        this.otterServiceReview!,
        !this.completeStepOverrides.includes(CompleteStepOverrideOption.ValidateInputs),
        !this.completeStepOverrides.includes(CompleteStepOverrideOption.ValidateWithPayer)
      )
        .then(res => {
          if (!res.nextStep) {
            throw new Error('Next step not defined in complete step response.')
          }
          debugLoggingService.log('Workflow Step Completed', res)
          this.stepAlert = undefined
          this.workflowStepId = res.nextStep.workflowStepId
          this.validationErrors = {}
          this.otterServiceReview = res.otterServiceReview
          this.updateBlocks(res.nextStep?.blocks)
          this.workflowProperties = res.nextStep.workflowProperties ?? []
          this.workflowCurrentStep = res.nextStep
          this.setWorkflowSteps(res.nextStep.path)
          sessionStorage.setItem('otterDraftDashboardId', this.otterServiceReview?.serviceReview?.dashboardId ?? 'null')
        })
        .catch(err => {
          const apiError = isApiError(err)
          if (apiError) {
            if (isStatusCode(apiError, 400)) {
              // this.stepAlert = defaultAlerts.failedToReachPayer
              this.stepAlert = {
                title: 'Unable to complete step',
                message: apiError.body?.title,
                type: AlertType.Error,
              }
              this.mapErrorsToServiceReviewProps(err.body?.errors)
            } else if (isStatusCode(apiError, 424)) {
              this.stepAlert = defaultAlerts.failedToReachPayer
            } else if (apiError.body?.detail) {
              this.stepAlert = {
                title: 'Unable to complete step',
                message: apiError.body.detail,
                type: AlertType.Error,
              }
            }
          }
          throw err // bubble up to 401 auth handling
        })
        .finally(() => {
          this.completingStep = false
        })
      },
    async getServiceReviewAtStep(workflowStepId) {
      await WorkflowService.getServiceReviewAtStep(this.serviceReview?.dashboardId!, workflowStepId)
        .then(res => {
          if (!res.nextStep) {
            throw new Error('Next step not defined in complete step response.')
          }
          this.workflowStepId = res.nextStep.workflowStepId
          this.validationErrors = {}
          this.otterServiceReview = res.otterServiceReview
          this.updateBlocks(res.nextStep?.blocks)
          this.workflowCurrentStep = res.nextStep
          this.workflowProperties = res.nextStep.workflowProperties ?? []
          this.onNewOtterServiceReviewSet()
        })
        .catch(err => {
          const apiError = isApiError(err)
          if(apiError) {
            this.validationErrors = apiError.body?.errors || apiError.body
          }
          throw err // bubble up to 401 auth handling
        })
    },
    async reloadWorkflowProperties(propertyChanged: string) {
      const request = {
        otterServiceReview: this.otterServiceReview,
        propertyChanged,
      }
      await WorkflowService.validateServiceReview(request)
        .then(this.appendResponse)
        .catch(err => {
          if(err.body?.errors) {
            this.mapErrorsToServiceReviewProps(err.body?.errors)
          }
        })
    },
    mapErrorsToServiceReviewProps(errors) {
      let errorObject = {}
      let props = [] as object[]

      for (const [key, value] of Object.entries(errors)) {
        let tempKey = key.split('otterServiceReview.').pop()?.split('serviceReview.').pop() ?? ''
        let tempValue = Array.isArray(value) ? value.join(' ') : <string>value
        errorObject[tempKey] = tempValue
        props.push({[tempKey]: tempValue})
      }
      this.validationErrors = errorObject

      useAlertStore().pushToast(
        { title: 'Error in fields',
          message: ``,
          properties: props,
          type: AlertType.Error,
        }, 
        false)
    },
    appendResponse(res: ExtendedNextWorkflowStepViewModel) {
      this.validationErrors = res.errors ?? {}
      this.otterServiceReview = res.otterServiceReview
      this.toastResetProps(res.nextStep?.workflowProperties)
      this.appendBlocks(res.nextStep?.blocks)
      this.appendWorkflowProperties(res.nextStep?.workflowProperties)
      this.onNewOtterServiceReviewSet()
    },
    toastResetProps(propChanges: WorkflowPropertyViewModel[] | null | undefined) {
      let resetProps = propChanges?.filter( p => p.wasReset && p.isUIEditableProperty)
      if(resetProps && resetProps.length) {
        let props = resetProps.map(p => {
          const block = this.currentStep?.blocks.find(b => b.config.blockType === p.blockType)
          const substringProp = p.propertyName?.split('.').pop() // in procedures/diagnosses/providers the propertie paths are 'procedures[0].prop' and the config just has 'prop'
          const propLabel = block?.config?.content?.find(c => c.prop === p.propertyName || c.prop === substringProp)?.label ?? 'Field'

          return {[p.propertyName!]: `${propLabel} was reset` }
        })

        useAlertStore().pushToast(
          { title: 'Fields were reset.',
            message: ' Please review your entries for completeness.',
            properties: props,
            type: AlertType.Warn,
          }, 
          false)
      }
    },
    appendWorkflowProperties(propChanges: WorkflowPropertyViewModel[] | null | undefined) {
      this.workflowProperties = propChanges?.reduce((properties, propChange) => {
        const existingPropIndex = properties.findIndex(p => p.propertyName === propChange.propertyName)
        if (!propChange.isAllowed) {
          if (existingPropIndex >= 0) {
            properties.splice(existingPropIndex, 1)
          }
          return properties
        }
        if (existingPropIndex < 0) {
          properties.push(propChange)
          return properties
        }

        properties.splice(existingPropIndex, 1, propChange)
        return properties
      }, [...this.workflowProperties ?? []]) ?? []
    },
    appendBlocks(blockChanges: WorkflowBlockViewModel[] | null | undefined) {
      const newBlocks = blockChanges?.reduce((blocks, blockChange) => {
        const existingBlockIndex = blocks.findIndex(p => p.workflowBlockId === blockChange.workflowBlockId)
        if (!blockChange.isAllowed) {
          if (existingBlockIndex >= 0) {
            blocks.splice(existingBlockIndex, 1)
          }
          return blocks
        }
        if (existingBlockIndex < 0) {
          blocks.push(blockChange)
          return blocks
        }

        blocks.splice(existingBlockIndex, 1, blockChange)
        return blocks
      }, [...this.blocks ?? []]) ?? []
      
      this.blocks = newBlocks.sort(orderSort)
    },
    updateBlocks(blocks: WorkflowBlockViewModel[] | null | undefined) {
      /**
       * The following block update logic allows for proper fade in/out transition to function correctly.
       *  1. Set blocks to an empty array
       *  2. Wait until next tick to trigger fade out
       *  3. Wait the 500ms fade out transition time
       *  4. Update blocks to the new array to trigger fade in
       */
      this.blocks = []
      nextTick(() => {
        setTimeout(() => {
          this.blocks = blocks?.sort(orderSort)
        }, 500)
      })
    },
    addAttachment(attachment: ServiceReviewAttachment) {
      if (!this.serviceReview) {
        return
      }

      if (!this.serviceReview.supplementalInformation) {
        this.serviceReview.supplementalInformation = {}
      }

      if (!this.serviceReview.supplementalInformation.attachments) {
        this.serviceReview.supplementalInformation.attachments = []
      }

      this.serviceReview.supplementalInformation.attachments.push(attachment)
      
      debugLoggingService.log('Attachment Added', { attachment })
    },
    removeAttachment(attachment: ServiceReviewAttachment) {
      this.serviceReview!.supplementalInformation!.attachments = this.serviceReview!.supplementalInformation!.attachments!.filter(a => a.id !== attachment.id)
      debugLoggingService.log('Attachment Removed', { attachment })
      if (this.isAuthAiAttachments && attachment.id && this.otterServiceReview) {
        BlobDocumentsService.removeBlobDocument({
          workflowId: this.workflow?.workflowId ?? 0,
          workflowStepId: this.otterServiceReview.workflowStepId ?? 0,
          paRequestId: this.otterServiceReview.paRequestId ?? undefined,
          organizationId: this.otterServiceReview.serviceReview?.organization?.id ?? '',
          blobDocumentId: parseInt(attachment.id),
          dashboardId: this.otterServiceReview.serviceReview?.dashboardId ?? undefined
        })
      }
    },
    async getBlobDocumentsForPaRequest() {
      if(!this.workflow?.workflowId || 
          !this.otterServiceReview?.workflowStepId ||
          (!this.otterServiceReview?.paRequestId && !this.otterServiceReview?.serviceReview?.dashboardId) || 
          !this.organizationId) {
        return
      }
      if (!this.serviceReview!.supplementalInformation) {
        this.serviceReview!.supplementalInformation = {}
      }

      await BlobDocumentsService.getBlobDocumentsForPaRequest(this.workflow?.workflowId,
        this.otterServiceReview?.workflowStepId,
        this.organizationId, 
        this.otterServiceReview?.paRequestId ?? undefined,
        this.otterServiceReview?.serviceReview?.dashboardId ?? undefined )
        .then((res) => {
          this.serviceReview!.supplementalInformation!.attachments = []
          res.forEach(upload => {
            const attachment = {
              id: `${upload.blobDocumentId}`,
              fileName: upload.fileName,
              mimeType: undefined,
              size: undefined,
              uri:undefined,
              dateReceived: dateService.formatDateForStorage(dateService.today()),
            } as ServiceReviewAttachment
            this.addAttachment(attachment)
          })
        })
    },
    async mapPatientAndSubscriber(patientAndSubscriber: PatientAndSubscriberViewModel) {
      try {
        const res = await PatientsService.mapPatientAndSubscriberSearchResult({
          otterServiceReview: this.otterServiceReview!,
          patientAndSubscriber,
        })
        this.appendResponse(res)
      }
      catch(err) {
        const apiError = isApiError(err)
        if (apiError) {
          this.mapErrorsToServiceReviewProps(apiError.body?.errors)
        }
      }
    },
    async mapRequestingProvider(requestingProvider: ServiceReviewRequestingProvider) {
      try {
        const res = await ProvidersService.mapRequestingProviderSearchResult({
          otterServiceReview: this.otterServiceReview!,
          requestingProvider,
        })
        this.appendResponse(res)
      }
      catch(err) {
        const apiError = isApiError(err)
        if (apiError) {
          this.mapErrorsToServiceReviewProps(apiError.body?.errors)
        }
      }
    },
    async removeRequestingProvider() {
      try {
        const res = await ProvidersService.removeRequestingProvider(this.otterServiceReview!)
        this.appendResponse(res)
      }
      catch(err) {
        const apiError = isApiError(err)
        if (apiError) {
          this.mapErrorsToServiceReviewProps(apiError.body?.errors)
        }
      }
    },
    async removePatientAndSubscriber() {
      try {
        const res = await PatientsService.removePatientAndSubscriber(this.otterServiceReview!)
        this.appendResponse(res)
      }
      catch(err) {
        const apiError = isApiError(err)
        if (apiError) {
          this.mapErrorsToServiceReviewProps(apiError.body?.errors)
        }
      }
    },
    async mapRenderingProvider(renderingProvider: ServiceReviewRenderingProvider | null, index: number, providerArrayProp: string = 'renderingProviders') {
      try {
        const res = await ProvidersService.mapRenderingProviderSearchResult({
          otterServiceReview: this.otterServiceReview!,
          renderingProvider,
          propertyName: `${providerArrayProp}[${index}]`,
        })
        this.appendResponse(res)
      }
      catch(err) {
        const apiError = isApiError(err)
        if (apiError) {
          this.mapErrorsToServiceReviewProps(apiError.body?.errors)
        }
      }
    },
    async removeRenderingProvider(index: number, providerArrayProp: string = 'renderingProviders') {
      try {
        const res = await ProvidersService.removeRenderingProvider({
          otterServiceReview: this.otterServiceReview!,
          propertyName: `${providerArrayProp}[${index}]`,
        })
        this.appendResponse(res)
      }
      catch(err) {
        const apiError = isApiError(err)
        if (apiError) {
          this.mapErrorsToServiceReviewProps(apiError.body?.errors)
        }
      }
    },
    async mapDiagnosisCode(diagnosis: ServiceReviewDiagnosis | null, index: number) {
      try {
        const res = await DiagnosesService.mapDiagnosisSearchResult({
          otterServiceReview: this.otterServiceReview!,
          diagnosis,
          propertyName: `diagnoses[${index}]`,
        })
        this.appendResponse(res)
      }
      catch(err) {
        const apiError = isApiError(err)
        if (apiError) {
          this.mapErrorsToServiceReviewProps(apiError.body?.errors)
        }
      }
    },
    async removeDiagnosis(index: number) {
      try {
        const res = await DiagnosesService.removeDiagnosis({
          otterServiceReview: this.otterServiceReview!,
          propertyName: `diagnoses[${index}]`,
        })
        this.appendResponse(res)
      }
      catch(err) {
        const apiError = isApiError(err)
        if (apiError) {
          this.mapErrorsToServiceReviewProps(apiError.body?.errors)
        }
      }
    },
    async mapProcedure(procedure: ServiceReviewProcedure | null, index: number) {
      try {
        const res = await ProceduresService.mapProcedureSearchResult({
          otterServiceReview: this.otterServiceReview!,
          procedure,
          propertyName: `procedures[${index}]`,
        })
        this.appendResponse(res)
      }
      catch(err) {
        const apiError = isApiError(err)
        if (apiError) {
          this.mapErrorsToServiceReviewProps(apiError.body?.errors)
        }
      }
    },
    async removeProcedure(index: number) {
      try{
        const res = await ProceduresService.removeProcedure({
          otterServiceReview: this.otterServiceReview!,
          propertyName: `procedures[${index}]`,
        })
        this.appendResponse(res)
      }
      catch(err) {
        const apiError = isApiError(err)
        if (apiError) {
          this.mapErrorsToServiceReviewProps(apiError.body?.errors)
        }
      }
    },
    async mapTransportLocation(transportLocation: ServiceReviewTransportLocation | null, index: number) {
      try {
        const res = await TransportLocationsService.mapTransportLocation({
          otterServiceReview: this.otterServiceReview!,
          transportLocation,
          propertyName: `transportLocations[${index}]`,
        })
        this.appendResponse(res)
      }
      catch(err) {
        const apiError = isApiError(err)
        if (apiError) {
          this.mapErrorsToServiceReviewProps(apiError.body?.errors)
        }
      }
    },
    async removeTransportLocation(index: number) {
      try {
        const res = await TransportLocationsService.removeTransportLocation({
          otterServiceReview: this.otterServiceReview!,
          propertyName: `transportLocations[${index}]`,
        })
        this.appendResponse(res)
      }
      catch(err) {
        const apiError = isApiError(err)
        if (apiError) {
          this.mapErrorsToServiceReviewProps(apiError.body?.errors)
        }
      }
    },
    async searchEligibility() {
      if (this.searchingEligibility || !this.otterServiceReview) {
        return
      }
      try {
        this.searchingEligibility = true
        var otterServiceReview = await PatientsService.searchEligibility(this.otterServiceReview)
        if (otterServiceReview) {
          this.otterServiceReview = otterServiceReview
          this.onNewOtterServiceReviewSet()
          this.eligibilitySearchResponse = { notFound: false }
        }
      }
      catch (err) {
        const apiError = isApiError(err)
        const problemDetail = apiError ? apiError.body as ProblemDetails : null
        const alert = {
          title: 'No Member Found',
          message: problemDetail?.detail ?? 'An error occurred, please try again.',
          type: AlertType.Error,
        } as Alert
        this.eligibilitySearchResponse = { notFound: true, alert } as EligibilitySearchResponse
      } finally {
        this.searchingEligibility = false
      }
    },
    resetEligibilitySearchResponse() {
      this.eligibilitySearchResponse = undefined
    },
    onNewOtterServiceReviewSet() {
      if (this.otterServiceReview?.epaResponse) {
        globalEventService.emit<EpaRedirectEventPayload>(GlobalEvents.EpaRedirect, { epaResponse: this.otterServiceReview.epaResponse })
      }
    },
  },
})