import * as tus from 'tus-js-client'
import debugLoggingService from '@/services/debugLoggingService'
import { OpenAPI, type BlobDocumentUIViewModel } from '@/api'
import { ThrottledQueue } from '@/types/throttledQueue'
import { AvScanResult, DecryptionResult, UploadResult, UploadStatus, type UploadState } from '@/types/upload'

function xhrUploadWithProgress(uploadState: UploadState): Promise<BlobDocumentUIViewModel[]> {
  const xhr = new XMLHttpRequest()
  xhr.withCredentials = true
  
  return new Promise((resolve, reject) => {
    xhr.upload.addEventListener('progress', (ev) => {
      uploadState.bytesSent = ev.loaded
      uploadState.bytesTotal = ev.total    
      debugLoggingService.log(`Upload | Progress Reported`, { 
        upload: uploadState,
        bytesSent: ev.loaded,
        bytesTotal: ev.total,
      })
    })

    const onError = (err) => {
      const erroResponse = err ?? 'There was an error uploading documents'
      return reject(erroResponse)
    }
    xhr.addEventListener('error', (err) => {
      onError(err)
    })

    xhr.addEventListener('loadend', () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        resolve(JSON.parse(xhr.response))
      } else {
        onError(xhr.response)
      }
    })

    const formData = new FormData()
    formData.append('WorkflowId', uploadState.workflowId.toString())
    if ( uploadState.paRequestId )
    {
      formData.append('PaRequestId', uploadState.paRequestId.toString())
    }
    formData.append('OrganizationId', uploadState.organizationId)
    formData.append('Description', uploadState.file.name)
    formData.append('File', uploadState.file)
    formData.append('WorkflowStepId', uploadState.workflowStepId.toString())
    if ( uploadState.dashboardId )
    {
      formData.append('DashboardId', uploadState.dashboardId)
    }

    xhr.open('POST', `${OpenAPI.BASE}/api/BlobDocuments/AddBlobDocument`, true)
    xhr.setRequestHeader("X-AVAILITY-CUSTOMER-ID", OpenAPI.HEADERS?.['X-AVAILITY-CUSTOMER-ID'] ?? '')
    xhr.send(formData)
  })
}

const authAiUploadService = {
  async upload(uploadState: UploadState): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (uploadState.status !== UploadStatus.queued) {
        return resolve()
      }

      uploadState.updateStatus(uploadState, UploadStatus.uploading)

      const onError = (err: any) => {
        let error = err instanceof Error ? err : new Error(err)
        let errorResponse = (() => {
          try {
            return JSON.parse(err)?.detail
          } catch {
            return err
          }
        })
        error.message = errorResponse() 
        uploadState.updateStatus(uploadState, UploadStatus.error)
        uploadState.error = error || 'There was an error uploading the attachemnt.'
        debugLoggingService.log(`AuthAI Upload | File Upload Error`, { upload: uploadState, error })
        return reject()
      }
  
      try {
        const response = await xhrUploadWithProgress(uploadState)

        // TODO: Can otter api just return the new new blob document?
        // Returning the entire new list made sense for Gorilla but doesn't play well with keeping the same upload state model
        // between "Gorillafied" and legacy essentials workflows.
        const docs = [...response.filter(r => r.fileName?.endsWith(uploadState.file.name))]
        docs.sort((a, b) => a.blobDocumentId - b.blobDocumentId)
        const doc = docs[docs.length - 1]
  
        uploadState.attachmentId = doc.blobDocumentId.toString()
        uploadState.attachmentUri = doc.fileName ?? undefined
      } catch (err) {
        return onError(`${err}`)
      }

      uploadState.updateStatus(uploadState, UploadStatus.success)
      resolve()
    })
  }
}

const resumableUploadService = {
  async upload(uploadState: UploadState): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (uploadState.status !== UploadStatus.queued) {
        return resolve()
      }
      const options = {
        ...this.getOptions(uploadState),
        onError: (error: Error | tus.DetailedError) => {
          uploadState.updateStatus(uploadState, UploadStatus.error)
          uploadState.error = error
          debugLoggingService.log(`Upload | File Upload Error`, { upload: uploadState, error })
          reject(error)
        },
        onSuccess: async () => {
          try {
            await this.scan(uploadState)
          } catch (err) {
            options.onError!(err as Error)
          }
          
          if (uploadState.result === UploadResult.accepted) {
            uploadState.updateStatus(uploadState, UploadStatus.success)
            resolve()
          } else if (uploadState.result === UploadResult.encrypted) {
            uploadState.updateStatus(uploadState, UploadStatus.encrypted)
            resolve()
          } else {
            options.onError!(new Error('Upload was not accepted by the server.'))
          }
        }
      }

      if (uploadState.result === UploadResult.encrypted) {
        try {
          await this.scan(uploadState)
        } catch (err) {
          options.onError!(err as Error)
        }

        // @ts-ignore - uploadState.result will change in scan
        if (uploadState.result === UploadResult.accepted) {
          uploadState.updateStatus(uploadState, UploadStatus.success)
          return resolve()
        } else {
          return options.onError!(new Error('File could not be decrypted.'))
        }
      }
      
      uploadState.updateStatus(uploadState, UploadStatus.uploading)
      debugLoggingService.log(`Upload | File Uploading`, { uploadState, options })
      const upload = new tus.Upload(uploadState.file, options)
      uploadState.abortCallback = async () => {
        debugLoggingService.log(`Upload | TUS Abort Triggered`, { upload: uploadState })
        /**
         * true for shouldTerminate will attempt to tell the server to delete the file
         * our TUS server does not support this and files are periodically automatically cleaned up
         */
         await upload.abort(false)
         return reject()
      }
  
      /**
       * Previous essentials behavior was as if the deprecated resume option were set to true.
       * This is no longer a default option, and this is the recommended way to preserve that functionality.
       * https://tus.io/blog/2020/05/04/tus-js-client-200
       */
      const previousUploads = await upload.findPreviousUploads()
      if (previousUploads.length > 0) {
        debugLoggingService.log(`Upload | Resuming Previous Upload`, { upload: uploadState })
        upload.resumeFromPreviousUpload(previousUploads[0])
      }
  
      upload.start()
    })
  },
  onBeforeRequest: (req: tus.HttpRequest) => {
    const xhr = req.getUnderlyingObject()
    xhr.withCredentials = true // send cookies
  },
  getBaseOptions(uploadState: UploadState): tus.UploadOptions {
    return {
      metadata:  {
        'availity-filename': uploadState.file.name,
        'availity-content-type': uploadState.file.type,
        'availity-attachment-name': 'N/A',
      },
    }
  },
  getOptions(uploadState: UploadState): tus.UploadOptions {
    return {
      ...this.getBaseOptions(uploadState),
      endpoint: `${OpenAPI.BASE}/api/Vault/upload/resumable/${uploadState.payerId}`,
      chunkSize: 3e6, // 3MB
      retryDelays: [0, 1000, 3000, 5000],
      removeFingerprintOnSuccess: true,
      fingerprint: () => Promise.resolve(uploadState.fingerprint),
      onProgress: (bytesSent: number, bytesTotal: number) => {
        uploadState.bytesSent = bytesSent
        uploadState.bytesTotal = bytesTotal
    
        debugLoggingService.log(`Upload | Progress Reported`, { 
          upload: uploadState,
          bytesSent,
          bytesTotal,
        })
      },
      onBeforeRequest: this.onBeforeRequest,
      onAfterResponse: (req: tus.HttpRequest, res: tus.HttpResponse) => {
        if (req.getMethod() !== 'POST') {
          return
        }

        if (uploadState.location && uploadState.bufferId) {
          return
        }

        const location = res.getHeader('Location')?.trim()
        if (!location) {
          return
        }

        uploadState.location = location
        const parts = location.split('/')
        uploadState.bufferId = parts[parts.length - 1]
      },
    }
  },
  async scan(uploadState: UploadState): Promise<void> {
    const pollingTime = 5000
    const maxAvScanRetries = 10

    const delay = (delayMs) => {
      return new Promise(resolve => setTimeout(resolve, delayMs));
    }

    return new Promise(async (resolve, reject) => {
      if (!uploadState.location) {
        return reject(new Error('Upload did not receive a location.'))
      }

      uploadState.updateStatus(uploadState, UploadStatus.scanning)  
      const options = this.getBaseOptions(uploadState)
      const httpStack = new tus.DefaultHttpStack(options)
  
      for (var i = 0; i < maxAvScanRetries; i++) {
        const request = httpStack.createRequest('HEAD', `${OpenAPI.BASE}${uploadState.location}`)
        this.onBeforeRequest(request)

        if (uploadState.password) {
          request.setHeader('Encryption-Password', uploadState.password)
        }

        const response = await request.send(null)
        
        uploadState.message = response.getHeader('Upload-Message') ?? ''
        uploadState.result = response.getHeader('Upload-Result')?.toLocaleUpperCase() as UploadResult
        uploadState.decryptionResult = response.getHeader('Decryption-Result')?.toLocaleUpperCase() as DecryptionResult
        uploadState.scanResult = response.getHeader('AV-Scan-Result')?.toLocaleUpperCase() as AvScanResult
        uploadState.bytesScanned = Number.parseInt(response.getHeader('AV-Scan-Bytes')  ?? '', 10)

        const deserializeHeaderArray = (header: string): string | undefined => {
          const arr = JSON.parse(response.getHeader(header)  ?? '')
          return arr?.length ? arr[0] : undefined
        }
        uploadState.attachmentUri = deserializeHeaderArray('References')
        uploadState.attachmentId = deserializeHeaderArray('Short-References')
        
        debugLoggingService.log(`Upload | Scan Response`, { uploadState, attemptNum: i })

        if (uploadState.result === UploadResult.rejected) {
          return reject(new Error('File rejected'))
        }
        
        if (uploadState.scanResult === AvScanResult.rejected) {
          return reject(new Error('Scan rejected file'))
        }

        if (uploadState.decryptionResult === DecryptionResult.rejected) {
          return reject(new Error('Decryption rejected'))
        }

        if (uploadState.decryptionResult !== DecryptionResult.pending) { 
          if (uploadState.result === UploadResult.encrypted) {
            return resolve()
          }

          if (uploadState.scanResult === AvScanResult.accepted) {
            return resolve()
          }
        }

        await delay(pollingTime)
      }
    })
  }
}


export const uploadQueue = new ThrottledQueue<UploadState>(resumableUploadService.upload)
export const authAiUploadQueue = new ThrottledQueue<UploadState>(authAiUploadService.upload)