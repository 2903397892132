/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BlockType {
    ImportTemp = 0,
    PatientSearch = 1,
    AdditionalEligibilityInformation = 2,
    LegacyPatientSearch = 3,
    ServiceInformation = 20,
    ServiceInformationWithQuantity = 30,
    Diagnoses = 50,
    Procedures = 55,
    GorillafiedEnterProcedures = 60,
    GorillafiedEditProcedures = 61,
    TransportLocations = 70,
    RenderingProvider = 100,
    LegacyRendingProvider = 101,
    RenderingProvidersFacilitiesSplit = 102,
    RenderingProvidersFacilitiesSplitLookup = 103,
    ReferringProvider = 105,
    RenderingFacility = 110,
    LegacyRequestingProvider = 111,
    RequestingProvider = 150,
    RequestingProviderLookup = 151,
    MarkdownContent = 300,
    Attachments = 500,
    AuthAIAttachments = 505,
    Message = 510,
    IsAuthRequired = 600,
    IsAuthRequiredResult = 610,
    ContactInfo = 700,
    AuthAIAttestation = 900,
    LegalText = 960,
    IsFEPMember = 961,
    Submit = 1000,
    SubmissionResult = 1010,
    HomeHealth = 2000,
    SSOExternal = 9999,
}
