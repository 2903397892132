import addressConfig from '@/components/blockConfigs/Address'
import type { BlockItemConfig } from '@/components/blockConfigs/_Types'

export default [
  {
    type: 'InputSelect',
    label: 'Provider Type',
    prop: 'roleCode'
  },
  {
    type: 'InputText',
    label: 'Group Name',
    prop: 'vendorGroupName'
  },
  {
    type: 'InputText',
    label: 'NPI',
    prop: 'npi'
  },
  {
    type: 'InputText',
    label: 'Payer Assigned Provider ID',
    prop: 'payerAssignedProviderId',
  },
  {
    type: 'InputSearch',
    label: 'Specialty',
    prop: 'specialtyCode'
  },
  {
    type: 'InputText',
    label: 'First Name',
    prop: 'firstName'
  },
  {
    type: 'InputText',
    label: 'Last Name',
    prop: 'lastName'
  },
  {
    type: 'InputText',
    label: 'Suffix',
    prop: 'suffix'
  },
  {
    type: 'InputText',
    label: 'Tax ID',
    prop: 'taxId'
  },
  {
    type: 'InputText',
    label: 'License Number',
    prop: 'licenseNumber'
  },
  {
    type: 'InputSearch',
    label: 'License State',
    prop: 'licenseStateCode'
  },
  {
    type: 'InputText',
    inputType: 'tel',
    label: 'Phone',
    prop: 'phone'
  },
  {
    type: 'InputText',
    label: 'Extension',
    prop: 'extension'
  },
  {
    type: 'InputText',
    inputType: 'tel',
    label: 'Fax',
    prop: 'fax'
  },
  {
    type: 'InputText',
    inputType: 'email',
    label: 'Email',
    prop: 'email'
  },
  {
    type: 'InputText',
    label: 'URL',
    prop: 'url'
  },
  {
    type: 'InputText',
    label: 'Contact Name',
    prop: 'contactName'
  },
  {
    type: 'InputSelect',
    label: 'Which provider is submitting this authorization request to the health plan?',
    prop: 'submittingProvider',
  },
  ...structuredClone(addressConfig),
] as BlockItemConfig[]