import addressConfig from '@/components/blockConfigs/Address'
import type { BlockItemConfig } from "./_Types";

export default [
  {
    type: 'InputText',
    label: 'Member ID',
    prop: 'memberId'
  },
  {
    type: 'InputDate',
    label: 'Patient Date of Birth',
    prop: 'birthDate'
  },
  {
    type: 'InputText',
    label: 'Patient Last Name',
    prop: 'lastName'
  },
  {
    type: 'InputText',
    label: 'Patient Middle Name',
    prop: 'middleName'
  },  
  {
    type: 'InputText',
    label: 'Patient First Name',
    prop: 'firstName'
  },
  {
    type: 'InputText',
    label: 'Patient Suffix',
    prop: 'suffixName'
  },
  {
    type: 'InputSelect',
    label: 'Patient Gender',
    prop: 'genderCode'
  },
  {
    type: 'InputSelect',
    label: 'Relationship to Subscriber',
    prop: 'subscriberRelationshipCode'
  },
  {
    type: 'InputText',
    label: 'Patient Account Number',
    prop: 'patientAccountNumber'
  },
  {
    type: 'InputText',
    label: 'Plan Code',
    prop: 'planCode'
  },
  {
    type: 'InputSearch',
    label: 'Condition',
    prop: 'conditionCode'
  },
  {
    type: 'InputSelect',
    label: 'Prognosis',
    prop: 'prognosisCode'
  },
  {
    type: 'InputText',
    label: 'Height',
    prop: 'height'
  },
  {
    type: 'InputText',
    label: 'Weight',
    prop: 'weight'
  },
  {
    type: 'InputText',
    label: 'Home Phone',
    prop: 'homePhone'
  },
  {
    type: 'InputText',
    label: 'Cell Phone',
    prop: 'cellPhone'
  },
  {
    type: 'InputText',
    label: 'Work Phone',
    prop: 'workPhone'
  },
  {
    type: 'InputText',
    label: 'Plan',
    prop: 'planName'
  },
  ...structuredClone(addressConfig),
] as BlockItemConfig[]